<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c bbef0 pb20">
      <div class="">
          企业信息
        </div>
        <div class="flex-a-c" v-if="!companyInfo.endTimeA">未授权</div>
        <div class="flex-a-c" v-else>
          <p class="mr10 fs7">
            {{companyInfo.createTimeA}}到{{companyInfo.endTimeA}}
            <span class="ml20" style="font-weight: bold;font-size: 18px;color: #67757c;">{{companyInfo.currentNum}}</span>人
          </p>
          <el-button
            class="v-primary ml10"
            type="primary"
            @click="goBack"
            >返回</el-button
          >
        </div>
    </div>
    <div class="d-flex p-t-15 p-b-15">
      <div class="w100 c677 fs7">企业LOGO</div>
      <div class="w50 h50 braduis50p c26 companyLogo" :style="'background-image:url(' + companyInfo.companyLogo + ')'">
      </div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677 ">企业名称</div>
      <div class="c26">{{companyInfo.companyName}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">所属行业</div>
      <div class="c26">{{companyInfo.industryName}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">企业公告</div>
      <div class="c26">{{companyInfo.companyNotice}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-enterprise-info15 fs7">
      <div class="w100 c677">企业员工</div>
      <div class="c26">{{companyInfo.allBusinessCardCount}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">客户人数</div>
      <div class="c26">{{companyInfo.allCustomerCount}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">创建时间</div>
      <div class="c26">{{companyInfo.createTimeA}}</div>
    </div>
    <div class="card-page-header" style="margin-top: 30px;">
      <div>管理员信息</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">姓名</div>
      <div class="c26">{{companyInfo.name}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">职位</div>
      <div class="c26">{{companyInfo.position}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">手机号码</div>
      <div class="c26">{{companyInfo.phone}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">微信号</div>
      <div class="c26">{{companyInfo.personalWx}}</div>
    </div>
    <div class="d-flex p-t-15 p-b-15 fs7">
      <div class="w100 c677">邮箱</div>
      <div class="c26">{{companyInfo.email}}</div>
    </div>

  </div>
</template>

<script>
import {
  getAdminCompanyInfo
} from "@/api/agentManage.js";
import {
  getDataTimeSec
} from "@/utils/index.js";

export default {
  data() {
    return {
      companyInfo:''
    };
  },
  
  mounted() {
    this.getTableData();
  },
  methods: {
    //请求预约数据
    getTableData() {
      let data = {
        companyId:this.$route.query.id
      };
      getAdminCompanyInfo(data)
        .then((res) => {
          res.createTime = getDataTimeSec(res.createTime)
          res.endTime = getDataTimeSec(res.endTime)
          
          this.companyInfo = res.data || "";
          this.companyInfo.createTimeA = getDataTimeSec(res.data.createTime)
          this.companyInfo.endTimeA = getDataTimeSec(res.data.endTime)
          console.log(this.companyInfo)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //返回上一页
    goBack(){
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.companyLogo {
  background-size: cover;
}
.c677 {
    color: #67757C;
}
.c26 {
    color: #263238;
}
.card-page-header {
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #f1f2f3;
    font-size: 14px;
    color: rgba(103, 117, 124, 1);
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
  }

</style>